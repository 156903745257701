/* latin */
@font-face {
  font-family: 'Baloo 2';
  src: url("./fonts/Baloo2-Medium.ttf") format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
	height: 100%;
	margin: 0;
}

body {
	margin: 0;
 //  	background: linear-gradient(-11deg, #114477, #002233 63%) fixed;
	// background-repeat: no-repeat;
	font-family: 'Baloo 2', cursive !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
// change the theme
$theme-colors: (
  "primary": #00a3bd,
  "secondary": #303741,
  "danger": #ff4136,
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.btn-primary {
	color: #000;
}



@tailwind base;
@tailwind components;
@tailwind utilities;

/*
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
*/