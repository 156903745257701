.navemail
{
	background-color: #cccccc;
	color: #000000a0;
	font-weight: bold;
	transition: all 0.5s ease;
}

.navemail:hover
{
	background-color: #00a3bd;
	color: #ffffff;
}
