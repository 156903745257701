.headline
{
	font-size: 3em;
}

.login-wrapper
{
	background-color: #f9f9f9;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
	margin-top: 5%;
	padding: 1em;
	box-shadow: 0px 4px 6px 1px #eee;
	border-radius: 13px;
	max-width: 40em;
}

.login-firebase-error
{
	color: #f31252;
}

.login-error
{
	color: #f31252;
	padding: 0em .5em .5em;

}

.login-label
{
	padding: .5em 0em;
}

.login-button
{
	background-color: #00a3bd;
	margin-top: 1em;
	padding: .5em;
	border-radius: .5em;
	box-shadow: 0px 4px 6px 1px #ccc;
	min-width: 10em;
}

.login-input
{
	border: solid .2em #ccc;
	border-radius: .5em;
	padding: .5em;
}

.login-spacer
{
	margin-top: 1em;
	margin-bottom: 1em;
	background-color: #eee;
	height: 3px;
	width: 100%;
}